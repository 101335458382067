import React from "react";

import Helm from "../../Helm/Helm";

const Page404 = () => {
    return (
        <>
            <Helm
                title={"404 Error Page - Real Estate Security"}
                description={
                    "404 Error"
                }
                link={"/404"}
                ogImage
                ogTitle={"404 Error Page - Real Estate Security"}
                ogDescription={
                    "404 Error"
                }
                author
            />
            <section className="about-us" id="about-us">
                <div className="body-a-body">
                    <div className="body-a">
                        <div className="body-a-left">
                            <img src="/images/logo_longtext.png" alt="" />
                        </div>
                        <div className="body-a-right">
                            <h4>Ojdå! Något gick snett!</h4>
                            <p>
                                Ett fel har uppstått.
                                <br />
                                <br />
                                Ta dig tillbaka tillhuvudmenyn och startsidan.{" "}
                                <a href="https://realestatesecurity.se">
                                    www.realestatesecurity.se
                                </a>
                            </p>
                            <a
                                className="cta-btn2 cta-btn2-var2"
                                href="https://realestatesecurity.se"
                            >
                                <p>Eller klicka här</p>
                                <i
                                    className="fas fa-chevron-right"
                                    onclick=""
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="body-b-body">
                    <div className="body-b">
                        <img
                            src="/images/jpg/5to1AR/wise_house_skarm-tvattstuga (1).jpg"
                            alt=""
                            style={{ width: "100%", marginRight: "0" }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Page404;
