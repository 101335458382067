import React from "react";

import "./contact-page.scss";
import Helm from "../../Helm/Helm";

import imgHead1 from "./kontakt_porttelefon.jpg";

const ContactPage = () => {
    return (
        <>
            <Helm
                title={"Kontakt - Real Estate Security"}
                description={
                    "Vårt mål är att besvara alla frågor inom 12 timmar. Tel: +46 732 09 82 20 från 9-17 vardagar. Mejl: info@realestatesecurity.se."
                }
                link={"/kontakt"}
                ogImage
                ogTitle={"Kontakt - Real Estate Security"}
                ogDescription={
                    "Vårt mål är att besvara alla frågor inom 12 timmar. Tel: +46 732 09 82 20 från 9-17 vardagar. Mejl: info@realestatesecurity.se."
                }
                author
            />
            <section className="contact-head">
                <div className="img">
                    <img id="img-scale" src={imgHead1} alt="Porttelefon"/>
                </div>
                <div className="title">
                    <h1>Kontakt</h1>
                </div>
            </section>

            <section className="contact">
                <div className="main">
                    <div className="main-left">
                        <div className="contact-box p">
                            <h4>Kontaktformulär</h4>
                            <form action="./form-handler.php" method="POST">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Namn"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    required
                                />
                                <input
                                    type="subject"
                                    name="subject"
                                    placeholder="Ärende"
                                    required
                                />
                                <textarea
                                    rows="8"
                                    name="message"
                                    placeholder="Meddelande"
                                    required
                                ></textarea>
                                <button type="submit" className="cta-btn1">
                                    <p>Skicka meddelande</p>
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="chevron-right"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                        className="fa-chevron-right"
                                    >
                                        <path
                                            fill="white"
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                        ></path>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="main-right">
                        <div className="contact-box">
                            <h4>Kontaktuppgifter</h4>
                            <a href="tel:467320982206">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="phone"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="fa-phone"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                                        className=""
                                    ></path>
                                </svg>
                                <span>
                                    <h5>+46 732 09 82 20</h5>
                                    <p>Från 9-17 vardagar.</p>
                                </span>
                            </a>
                            <a href="mailto:info@realestatesecurity.se">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="envelope"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="fa-envelope"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                                        className=""
                                    ></path>
                                </svg>
                                <span>
                                    <h5>info@realestatesecurity.se</h5>
                                    <p>Mejla ditt ärende till oss!</p>
                                </span>
                            </a>
                            <a href="/">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="home"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    className="fa-home"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                        className=""
                                    ></path>
                                </svg>
                                <address style={{ fontStyle: "normal" }}>
                                    <h5 style={{ fontWeight: "600" }}>
                                        Svampskogsvägen 23
                                    </h5>
                                    <p>186 55 Vallentuna, Sverige.</p>
                                </address>
                            </a>

                            <div className="location" id="google-maps">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4544.440448482602!2d18.081399464677915!3d59.51112730877318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9a06e7a4586b%3A0x26ac7164a0f9cbe5!2sSvampskogsv%C3%A4gen%2023%2C%20186%2055%20Vallentuna!5e0!3m2!1sen!2sse!4v1630404880105!5m2!1sen!2sse"
                                    width="100%"
                                    height="200px"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    title="e"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactPage;
