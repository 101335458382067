import React from "react";
import { Link } from "react-router-dom";

const CtaBtn3 = ({link, text}) => {
    return (
        <Link className="cta-btn3" to={link}>
            <p>{text}</p>
        </Link>
    );
};

export default CtaBtn3;
