import React from "react";

import "./body-g.scss";

const BodyG = ({ title, img, id, desc1, desc2, desc3 }) => {
    return (
        <div className="body-g-body" id={id}>
            <div className="body-g">
                <div className="top">
                    <img
                        src={img}
                        alt=""
                    />
                    <div className="title">
                        <div>
                            <h3>{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="bottom-col">
                        <p>{desc1}</p>
                    </div>
                    <div className="bottom-col">
                        <p>{desc2}</p>
                    </div>
                    <div className="bottom-col">
                        <p>{desc3}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BodyG;
