import React from "react";
import ProductCard from "./ProductCard/ProductCard";

const ProductCards = ({products, random}) => {
    

    return (
        <div className="product-list">
            {products.map((product) => (
                <ProductCard
                    link={product.link}
                    title={product.title}
                    price={product.price}
                    img={product.squareImg}
                    imgAlt={product.images[0].alt}
                    key={product.id}

                    random={random}
                />
            ))}
        </div>
    );
};

export default ProductCards;
