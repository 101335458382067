import React from 'react'
import CtaBtn2 from '../buttons/CtaBtn2/CtaBtn2'

import './body-b.scss';
import './body.scss';

const BodyB = ({img, imgAlt, title, desc, link, btnTitle}) => {
    return (
        <div className="body-b-body">
            <div className="body-b">
                <div className="body-b-left">
                    <h4>{title}</h4>
                    <p>{desc}
                    </p>
                    <CtaBtn2 btnClass="cta-btn2 cta-btn2-var2" link={link} title={btnTitle}/>
                    
                </div>
                <div className="body-b-right">
                    <img src={img} alt={imgAlt} loading="lazy"/>
                </div>
            </div>
        </div>
        
    )
}

export default BodyB
