import React from "react";
import CtaBtn2 from "../../buttons/CtaBtn2/CtaBtn2";
import Helm from "../../Helm/Helm";

import imgHead from "./wise_house_skarm-trapphus_alt1.jpg";
import img1 from "./2ca25727-d0ca-4d0b-8f94-4b0f4e80de8f.jpg";
import Icons from "../../Icons/Icons";

import "./media-page.scss";

const MediaPage = () => {
    return (
        <>
            <Helm
                title={"Media - Real Estate Security"}
                description={
                    "Läs om våra produktnyheter och våra referenskunder."
                }
                link={"/media"}
                ogImage
                ogTitle={"Media - Real Estate Security"}
                ogDescription={
                    "Läs om våra produktnyheter och våra referenskunder."
                }
                author
            />
            <section className="media-page">
                <div className="media-page-box">
                    <hr />
                    <img src={imgHead} alt="" />
                    <div className="media-page-box-top">
                        <h1>Mediarummet</h1>
                        <div className="p">
                            <p>
                                Följ med oss på de senaste uppdateringarna,
                                <br />
                                nyheterna, produktera och mera.
                            </p>
                        </div>
                    </div>
                    <div className="media-page-box-bottom">
                        <Icons/>
                    </div>
                </div>
            </section>

            <section className="media-news">
                <div className="media-news-title">
                    <h2>NYHETER</h2>
                    <hr />
                </div>
                <div className="media-news-body">
                    <div className="media-news-body-row">
                        <div className="media-news-body-row-col">
                            <img src={img1} alt="" />
                            <div className="date">
                                <time>
                                    <p>28-10-2021</p>
                                </time>
                            </div>
                        </div>
                        <div className="media-news-body-row-col">
                            <div className="news">
                                <div className="news-info">
                                    <h4>Ny samarbetspartner</h4>
                                    <p>
                                        Vi är mycket glad att få presentera vår
                                        samarbetspartner, säkerhetsexperten Jens
                                        Petersen, som idag, 28 december 2021,
                                        publiceras i Aftonbladets artikel om det
                                        som kallas Panikrum eller Panic Room.
                                        Vi, Real Estate Security, har ett
                                        samarbete med Jens när det gäller
                                        säkerhetslösningar. Så bokar ni ett möte
                                        med oss kommer ni troligen också träffa
                                        Jens.
                                    </p>
                                    <CtaBtn2
                                        btnClass="cta-btn2 cta-btn2-var2"
                                        link="https://www.aftonbladet.se/nyheter/a/y4K5rx/radsla-for-ran-far-fler-rika-att-bygga-panikrum?fbclid=IwAR2_egcIIo76-qTLauW1wNofSTomqpW4pQiGin6PfP_8VnewXXsdwLAjUZM"
                                        href
                                        title="Läs mer"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider">
                        <p></p>
                    </div>
                    <div className="media-news-body-row reorder">
                        <div className="media-news-body-row-col">
                            <div className="news">
                                <div className="news-info">
                                    <h4>Nyhetstitel</h4>
                                    <p>
                                        Nemonatur aut odit aut fugit, sed quia
                                        consequuntur magni dolores eos qui
                                        ratione voluptatem sequi nesciunt. Neque
                                        porro quisquam est, qui dolorem.
                                    </p>
                                    <CtaBtn2
                                        btnClass="cta-btn2 cta-btn2-var2"
                                        link=""
                                        title="Läs mer"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="media-news-body-row-col">
                            <img src={imgHead} alt="" />
                            <div className="date">
                                <time>
                                    <p>-</p>
                                </time>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MediaPage;
