import React from "react";

import BodyF from "../../body/BodyF";
import Helm from "../../Helm/Helm";

import ProductCards from "../../ProductCards/ProductCards";
import products from "../../../data/products/products";

import "./sortiment-page.scss";
import { Link } from "react-router-dom";

const SortimentPage = () => {
    return (
        <>
            <Helm
                title={"Sortiment - Real Estate Security"}
                description={"Vi arbetar med säkerhetslösningar som är användarvänliga, flexibla och skalbara."}
                link={"/sortiment"}
                ogImage
                ogTitle={"Sortiment - Real Estate Security"}
                ogDescription={"Vi arbetar med säkerhetslösningar som är användarvänliga, flexibla och skalbara."}
                author
            />
            <section className="products">
                <div className="main">
                    <div className="left" id="subheadMenu">
                        <ul>
                            <div
                                className="mobile-swipe-arrow"
                                id="mobile-swipe-arrow"
                            >
                                <i className="fas fa-chevron-right"></i>
                            </div>
                            {products.map((product) => (
                                <Link to={"/sortiment/" + product.link} key={product.id}>
                                    <li>
                                        <p>{product.title}</p>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className="right">
                        <div className="head-img">
                            <img src="images/jpg/whatshot-aie.jpg" alt="" />
                        </div>
                        <div className="title-box">
                            <h1>Sortiment</h1>
                            <p>
                                Nedan följer ett urval av alla de produkter och
                                tjänster vi er för att förhindra inbrott,
                                vandalisering, rån, kriminell verksamhet,
                                skadegörelse m.m. Vi har nogrannt valt våra
                                leverantörer, dels för att de har modern teknik,
                                som är på användarens villkor, dels för att de
                                är flexibla och bidrar till att många av våra
                                produkter och tjänster kan skapas efter era
                                önskemål.
                            </p>
                        </div>
                        <div className="product">
                            <div className="title">
                                <h3>Produkter</h3>
                            </div>
                            <ProductCards products={products}/>
                        </div> 
                    </div>
                </div>
            </section>
            <section className="any-questions">
                <BodyF title1="Kontakta oss" titleLink1="/kontakt"/>
            </section>
        </>
    );
};

export default SortimentPage;
