import React from "react";
import { Link } from "react-router-dom";

import imgH1 from "./wise_house_skarm-trapphus_alt1.jpg";
import imgH2 from "./bandit_dimgenerator.jpg";
import imgH3 from "./_MG_3982-Edit.jpg";

import img1 from "./Wise House.jpg";
import img2 from "./woodsteel-torterolore.png";
import img3 from "./Plegium.png";
import img4 from "./Milesight färg.png";
import img5 from "./Bandit.jpg";
import img6 from "./Ekströms glas.jpg";
import img7 from "./powerupsolutionlogo.jpg";

import Helm from "../../Helm/Helm";

import "./partner-page.scss";

const PartnerPage = () => {
    return (
        <>
            <Helm
                title={"Samarbetspartners - Real Estate Security"}
                description={"Vi har valt de bästa leverantörerna med moderna produkter som kan utvecklas med oss och våra kunder."}
                link={"/policy"}
                ogImage
                ogTitle={"Samarbetspartners - Real Estate Security"}
                ogDescription={"Vi har valt de bästa leverantörerna med moderna produkter som kan utvecklas med oss och våra kunder."}
                author
            />
            <section className="partners-page">
                <div className="partners-page-box">
                    <div className="partners-page-box-container">
                        <img
                            className="img1"
                            src={imgH1}
                            alt=""
                        />
                        <img
                            className="img2"
                            src={imgH2}
                            alt=""
                        />
                        <img
                            className="img3"
                            src={imgH3}
                            alt=""
                        />
                    </div>
                    <div className="title">
                        <h1>Samarbetspartners</h1>
                    </div>
                </div>
            </section>
            <section className="companies">
                <h4>Företag</h4>
                <div className="companies-box">
                    <a className="companies-box-container" href="https://wisehouse.se/">
                        <img src={img1} alt="Wise House" />
                    </a>
                    <a className="companies-box-container" href="https://woodsteel.se/lagenhetsdorr-ws3/?gclid=CjwKCAiAo4OQBhBBEiwA5KWu_7z1oVUIb9PfpDCLEKZw29WId68b-pWlzzKwVdP-0vZfGAM__yf79hoCaycQAvD_BwE">
                        <img
                            src={img2}
                            alt="TEC WOODSTEEL AB"
                        />
                    </a>
                    <a className="companies-box-container" href="https://www.plegium.se/">
                        <img src={img3} alt="Plegium" />
                    </a>
                    <a className="companies-box-container" href="https://www.milesight.com/">
                        <img src={img4} alt="Milesight" />
                    </a>
                    <a className="companies-box-container" href="https://www.banditsverige.se/">
                        <img src={img5} alt="Bandit" />
                    </a>
                    <a className="companies-box-container" href="https://www.ekstromsglas.se/">
                        <img src={img6} alt="Ekströms glas" />
                    </a>
                    <a className="companies-box-container" href="https://www.powerupsolution.se/">
                        <img
                            src={img7}
                            alt="PowerUp Solution"
                        />
                    </a>
                </div>
            </section>
        </>
    );
};

export default PartnerPage;
