import React from "react";
import logoLongText from "./logo_longtext.png";
import { Link } from "react-router-dom";
import MenuItem from "../MenuItem/MenuItem";
import MenuButton from "./MenuButton/MenuButton";
import SubMenuContent from "./SubMenuContent/SubMenuContent";

const MobileMenuBar = ({ toggleMenu, open }) => {
    return (
        <div className="menu-content" id="menu">
            <div className="logo">
                <picture>
                    <Link to="/" onClick={toggleMenu}>
                        <img src={logoLongText} loading="auto" alt="" />
                    </Link>
                </picture>
            </div>
            <ul>
                <li>
                    <Link to="/sortiment" toggleMenu={toggleMenu}>
                        Produkter
                    </Link>
                    <SubMenuContent>
                        <MenuItem
                            link="/produkter/trygghetsspray"
                            title="SMART Trygghetsspray"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/produkter/inbrottsskyddande-glas"
                            title="Inbrottsskyddande glas"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/produkter/digitala-tjanster"
                            title="Digitala fastighetstjänster"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/produkter/sakerhetsdorrar"
                            title="Säkerhetsdörrar"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/produkter/overvakningskameror"
                            title="Övervakningskameror"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/produkter/dimgeneratorer"
                            title="Dimgeneratorer"
                            onClick={toggleMenu}
                        />
                    </SubMenuContent>
                </li>
                <li>
                    <Link to="/om-oss" onClick={toggleMenu}>
                        Om oss
                    </Link>
                    <SubMenuContent>
                        <MenuItem
                            link="/om-foretaget"
                            title="Om företaget"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/vart-erbjudande"
                            title="Vårt erbjudande"
                            onClick={toggleMenu}
                        />
                        <MenuItem
                            link="/vart-team"
                            title="Vårt team"
                            onClick={toggleMenu}
                        />
                    </SubMenuContent>
                </li>
                <MenuItem
                    link="/kontakt"
                    title="Kontakt"
                    onClick={toggleMenu}
                />
                <MenuItem
                    link="/samarbetspartners"
                    title="Samarbetspartners"
                    onClick={toggleMenu}
                />
                <MenuItem link="/media" title="Media" onClick={toggleMenu} />
            </ul>
            
            <MenuButton toggleMenu={toggleMenu} open={open} onClick={toggleMenu} />
        </div>
    );
};

export default MobileMenuBar;
