import React, { useState } from "react";

const SubMenuContent = (props) => {
    const [open, setOpen] = useState(false);

    function toggleOpenSub() {
        setOpen(!open);
    }

    return (
        <>
            <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="fa-sort-down"
                onClick={toggleOpenSub}
            >
                <path
                    fill="currentColor"
                    d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
                ></path>
            </svg>
            {open && (
                <div className="sub-menu-content" id="sub-product-menu">
                    <ul>{props.children}</ul>
                </div>
            )}
        </>
    );
};

export default SubMenuContent;
