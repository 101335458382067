import React from 'react'

const Slide = ({id, alt, loading, src}) => {
    return (
        <div>
            <img src={src} alt={alt} loading={loading} />
        </div>
    )
}

export default Slide
