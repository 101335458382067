import React from "react";
import CtaBtn1 from "../buttons/CtaBtn1/CtaBtn1";
import CtaBtn3 from "../buttons/CtaBtn3/CtaBtn3";

import './body-d.scss';
import './body.scss';

const BodyD = ({imgCol1, imgCol2, imgCol3, imgCol4, imgCol5, imgCol6}) => {
    return (
        <div className="body-d-body">
            <div className="body-d-title">
                <div className="body-d-title-box">
                    <h2>Känn trygghet och säkerhet som aldrig innan.</h2>
                </div>
            </div>
            <div className="body-d">
                <div className="body-d-col">
                    <img
                        src={imgCol1}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>Digitala fastighetstjänster</h4>
                    <p>
                        Vi erbjuder en smart IP-baserad plattform där alla i
                        fastigheten får tillgång till smarta hem-tjänster direkt
                        i lägenheten, i trapphuset eller via mobilen. Det gör
                        livet enklare och säkrare för de boende.
                    </p>
                    <CtaBtn3 link="produkter/digitala-tjanster" title="Läs mer"/>
                </div>

                <div className="body-d-col">
                    <img
                        src={imgCol2}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>Övervakningskameror</h4>
                    <p>
                        Vi erbjuder en knivskarp kameraövervakning, med en av
                        marknadens bästa upplösningar (upp till 12MP).
                        Produkterna har AI-teknik, vilket effektiviserar
                        övervakningen.
                    </p>
                    <CtaBtn3 link="produkter/overvakningskameror" title="Läs mer"/>
                </div>
                <div className="body-d-col">
                    <img
                        src={imgCol3}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>Säkerhetsdörrar</h4>
                    <p>
                        Gällande säkerhetsdörrar erbjuder vi produkter med
                        Svensk eller Italiensk kvalité och design. Alltid med
                        högsta kraven på säkerhet i Sverige. I vårt ortiment
                        finns även entréportar.
                    </p>
                    <CtaBtn3 link="produkter/sakerhetsdorrar" title="Läs mer"/>
                </div>
                <div className="body-d-col">
                    <img
                        src={imgCol4}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>Inbrottsskyddande glas</h4>
                    <p>
                        Det krossäkra glaset vi erbjuder är uppemot 300 gånger
                        starkare än ett vanligt glas! Glaset klarar försök med
                        kofot, yxa, tyngder – utan problem!
                    </p>
                    <CtaBtn3 link="produkter/inbrottsskyddande-glas" title="Läs mer"/>
                </div>
                <div className="body-d-col">
                    <img
                        src={imgCol5}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>Dimgeneratorer</h4>
                    <p>
                        Med en dimgerator skapar du ett fantastiskt bra skydd
                        för din personal, ditt företag och din bostad. Tänk på
                        att det varje dag rånas minst en butik i Sverige, varje
                        dag sker inbrott i lager och bostäder som innehåller
                        dyrbar utrustning.
                    </p>
                    <CtaBtn3 link="produkter/dimgeneratorer" title="Läs mer"/>
                </div>
                <div className="body-d-col">
                    <img
                        src={imgCol6}
                        alt=""
                        style={{width: "108 px"}}
                        loading="lazy"
                    />
                    <h4>SMART Trygghetsspray</h4>
                    <p>
                        Vår SMART Trygghetsspray är en laglig och kraftigt
                        ögonirriterande mentolbaserad försvarsspray med röd
                        färg, kraftfull siren, LED-lampor som blinkar samt
                        gratis automatiska positions-SMS och telefonsamtal till
                        dina nödkontakter.
                    </p>
                    <CtaBtn3 link="produkter/trygghetsspray" title="Läs mer"/>
                </div>
            </div>
            <div className="buttons">
                <CtaBtn1 btnClass="cta-btn1 cta-btn1-var2 cta-btn-nospace" link="/sortiment" title="PRODUKTER"/>
            </div>
        </div>
    );
};

export default BodyD;
