import React from 'react'
import CtaBtn2 from '../buttons/CtaBtn2/CtaBtn2'

import './body-a.scss';
import './body.scss';

const BodyA = ({img, imgAlt, title, desc, link, btnTitle}) => {
    return (
        <div className="body-a-body">
        <div className="body-a">
            <div className="body-a-left">
                <img src={img} alt={imgAlt} loading="lazy"/>
            </div>
            <div className="body-a-right">
                <h4>{title}</h4>
                <p>{desc}</p>
                <CtaBtn2 btnClass="cta-btn2 cta-btn2-var2" link={link} title={btnTitle}/>
            </div>
        </div>
    </div>
    )
}

export default BodyA
