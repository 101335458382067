import React from "react";
import { Route, Routes } from "react-router-dom";

import { IndexPage, NavBar, Footer, SortimentPage, PartnerPage, MediaPage, ContactPage, AboutUsPage, ProductPage, AboutCompanyPage, OurOfferPage, OurTeamPage, Policy, Page404 } from "./components";

import ScrollToTop from "./components/hooks/ScrollToTop"

const App = () => {
    return (
        <>
            <NavBar />
            <ScrollToTop/>
            <Routes>
                
                <Route path="/" exact element={<IndexPage />} />
                {/* Sortiment Pages */}
                <Route path="/sortiment" element={<SortimentPage />} />
                    <Route path="/sortiment/:productId" element={<ProductPage />} />
                <Route path="/samarbetspartners" element={<PartnerPage />} />
                <Route path="/media" element={<MediaPage />} />
                <Route path="/kontakt" element={<ContactPage />} />
                {/* About us Pages */}
                <Route path="/om-oss" element={<AboutUsPage />} />
                <Route path="/om-foretaget" element={<AboutCompanyPage />} />
                <Route path="/vart-team" element={<OurTeamPage />} />
                <Route path="/vart-erbjudande" element={<OurOfferPage />} />

                {/* Policy */}
                <Route path="/policy" element={<Policy />} />
                {/* 404 Error */}
                
                <Route path="/404" element={<Page404 />} />
            </Routes>
            <Footer />
        </>
    );
};

export default App;
