import React from "react";

import AboutUsBox from "../../AboutUsBox/AboutUsBox";

import BodyG from "../../body/BodyG";
import BodyF from "../../body/BodyF";
import Helm from "../../Helm/Helm";

import "./our-offer-page.scss";

const OurOfferPage = () => {
    return (
        <>
            <Helm
                title={"Vårt erbjudande - Real Estate Security"}
                description={
                    "Genom flexibla och skalbara säkerhetsprodukter anpassar vi oss efter ditt behov."
                }
                link={"/vart-erbjudande"}
                ogImage
                ogTitle={"Vårt erbjudande - Real Estate Security"}
                ogDescription={
                    "Genom flexibla och skalbara säkerhetsprodukter anpassar vi oss efter ditt behov."
                }
                author
            />
            <section className="om-oss-page">
                <div className="om-oss-page-box">
                    <AboutUsBox page="4" />
                    <div className="om-oss-page-box-bottom">
                        <img src="images/jpg/wise_house_skarm-lgh.jpg" alt="" />
                        <div className="title-var1">
                            <h1>Vårt erbjudande</h1>
                            <div className="p">
                                <p>
                                    Ett av våra mål är att i så stor
                                    utsträckning som möjligt sätta stopp för
                                    inbrott, vandalisering, rån, kriminell
                                    verksamhet och skadegörelse, genom att
                                    erbjuda marknadens vassaste produkter från
                                    utvalda leverantörer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="om-oss-page om-oss-page-menu">
                <div className="om-oss-page-box">
                    <div className="om-oss-page-box-top" id="subheadMenu">
                        <ul>
                            <a href="#smart-trygghetsspray">
                                <li>
                                    <p>SMART Trygghetsspray</p>
                                </li>
                            </a>
                            <a href="#digitala-fastighetstjanster">
                                <li>
                                    <p>Digitala fastighetsfastighetstjänster</p>
                                </li>
                            </a>
                            <a href="#inbrottsskyddande-glas">
                                <li>
                                    <p>Inbrottsskyddande glas</p>
                                </li>
                            </a>
                            <a href="#dimgeneratorer">
                                <li>
                                    <p>Dimgeneratorer</p>
                                </li>
                            </a>
                            <a href="#sakerhetsdorrar">
                                <li>
                                    <p>Säkerhetsdörrar</p>
                                </li>
                            </a>
                            <a href="#overvakningskameror">
                                <li>
                                    <p>Övervakningskameror</p>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="about-us" id="about-us">
                <div className="body-b-body">
                    <div className="body-b">
                        <div className="body-b-left">
                            <h4>Vårt erbjudande</h4>
                            <p>
                                Vi erbjuder tjänster som skall förhindra
                                inbrott, vandalisering, rån, kriminell
                                verksamhet och skadegörelse, genom att erbjuda
                                marknadens vassaste produkter från utvalda
                                leverantörer.
                            </p>
                        </div>
                        <div className="body-b-right">
                            <img
                                src="/images/jpg/wise_house_tagglasare.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                <BodyG
                    img="images/jpg/5to1AR/trygghetsspray_flash_kvad.png"
                    title="SMART Trygghetsspray"
                    id="smart-trygghetsspray"
                    desc1="SMART Trygghetsspray är en laglig och kraftigt ögonirriterande mentolbaserad försvarsspray med röd färg, kraftfull siren, 'LED-lampor som blinkar samt gratis automatiska positions-SMS och telefonsamtal till dina nödkontakter."
                    desc2="Plegium är de enda försvarssprayerna i
                                    världen som automatiskt skickar SMS med din
                                    position samt ringer upp din anhöriga när du
                                    avfyrar sprayen, så att du kan få hjälp
                                    snabbare."
                    desc3="Alla Plegium försvarssprayer innehåller en
                                    vätska baserad på högkoncentrerad mentol,
                                    vilket är lagligt och licensfritt för
                                    privatpersoner i Sverige."
                />
                <BodyG
                    img="images/jpg/5to1AR/wise_house_skarm-tvattstuga (1).jpg"
                    title="Digitala fastighetstjänster"
                    id="digitala-fastighetstjanster"
                    desc1="Med våra digitala fastighetstjänster får ni
                    ett av marknadens bästa informations- och
                    bokningssystem, högkvalitativa porttelefoner
                    och passagesystem."
                    desc2="Lägg därtill snygg och modern design med
                    touchfunktion på skärmarna, så får ni mycket
                    snygga trapphus."
                    desc3="Mjukvaran för detta är dessutom utformade
                    efter användarens behov, men ändå med högsta
                    tekniska nivå, t.ex. använder vi AI-teknik i
                    våra övervakningskameror."
                />
                <BodyG
                    img="images/jpg/5to1AR/inbrottsskyddande_glas.jpg"
                    title="Krossäkra glas"
                    id="krossakra-glas"
                    desc1="Vårt krossäkra glas är uppemot 300 gånger
                    starkare än ett vanligt glas!"
                    desc2="Glaset är även energieffektivt genom att det
                    ger viktiga u-värdesförbättringar. Det
                    krossäkra glaset ger också en ljudreduktion
                    om 30 db."
                    desc3="Det krossäkra glaset kan formas enligt dina
                    egna önskemål. Glaset fungerar lika bra i
                    kommersiella som i privata sammanhang."
                />
                <BodyG
                    img="images/jpg/5to1AR/dimgeneratorer_dimma.jpg"
                    title="Dimgeneratorer"
                    id="dimgeneratorer"
                    desc1="Fyller en lokal på 200kvm på ca 4 sekunder.
                    Det kallar vi EFFEKTIVT SKYDD!"
                    desc2="Dimgeneratorn är kompatibel med ditt larm
                    och dessutom godkänt av Svensk Handel"
                    desc3="Röken är ofarlig och lämnar inga spår på
                    varken kläder eller saker."
                />
                <BodyG
                    img="images/jpg/5to1AR/anpassad-lagenhetsdorr.jpg"
                    title="Säkerhetsdörrar"
                    id="sakerhetsdorrar"
                    desc1="Vi erbjuder säkerhetsdörrar i stål med en
                    kombination av Svensk kvalité och Italiensk
                    design. Dörrarna uppfyller de högsta kraven
                    på säkerhet och är certifierade enligt
                    RISE.SE"
                    desc2="Utbudet består av lägenhetsdörrar,
                    entréportar samt källar-och förrådsdörrar.
                    För den som vill bygga sitt eget
                    “panic-room” så har vi specialdörrar för det
                    också."
                    desc3="Självklart kan dörrarna anpassas till
                    digitiala låssystem som styrs via en app.
                    App:ens funktion lagrar dessutom alla
                    passager historiskt."
                />
                <BodyG
                    img="images/jpg/5to1AR/whatshot-aie.jpg"
                    title="Övervakningskameror"
                    id="overvakningskameror"
                    desc1="Vi erbjuder en knivskarp kameraövervakning,
                    med en av marknadens bästa upplösningar som
                    ger dig detaljrik information."
                    desc2="Övervakningskameror passar utmärkt för
                    kontoret, lagret, flerfamiljsus och
                    privatbostäder. Syftet är med övervakning är
                    att att skydda de saker och personer som du
                    älskar."
                    desc3="Integritetsmyndigheten, IMY, har också
                    lättat på reglerna gällande
                    bostadsrättsföreningar som vill sätta upp
                    övervakningskameror. Kontakta oss så
                    berättar vi mer"
                />
            </section>

            <section className="any-questions">
                <BodyF
                    title1="Om företaget"
                    titleLink1="/om-foretaget"
                    title2="Om oss"
                    titleLink2="/om-oss"
                    title3="Vårt team"
                    titleLink3="/vart-team"
                />
            </section>
        </>
    );
};

export default OurOfferPage;
