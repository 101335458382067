import React from "react";

import BodyF from "../../body/BodyF";
import TeamBox from "../../TeamBox/TeamBox";
import Helm from "../../Helm/Helm";

import AboutUsBox from "../../AboutUsBox/AboutUsBox";

import "./our-team-page.scss";

const OurTeamPage = () => {
    return (
        <>
            <Helm
                title={"Vårt team - Real Estate Security"}
                description={"Vi på Real Estate Security. Vilka vi är. Kjell..."}
                link={"/vart-team"}
                ogImage
                ogTitle={"Vårt team - Real Estate Security"}
                ogDescription={"Vi på Real Estate Security. Vilka vi är. Kjell..."}
                author
            />
            <section class="om-oss-page">
                <div class="om-oss-page-box">
                    <AboutUsBox page="3" />
                    <div class="om-oss-page-box-bottom">
                        <img src="images/jpg/Om oss.jpg" alt="" />
                        <div class="title-var1">
                            <h1>Vårt team</h1>
                            <div class="p">
                                <p>
                                    Vårt team är en den perfekta mixen. Vi
                                    blandar gedigen erfarenhet från branschen
                                    med nytänkande och ungdomlig energi.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-us" id="about-us">
                <div class="team">
                    <div class="title">
                        <h3>Kontaktpersoner</h3>
                    </div>
                    <div class="team-flex">
                        <TeamBox
                            img="/images/jpg/kjell-lindqvist.jpg"
                            background="/images/jpg/4to3AR/bandit_dimgenerator.jpg"
                            desc="Jag har gedigen säljerfarenhet av
                            digitala tjänster från bolag som Tele2,
                            UPC/Comhem, Ownit, Telenor och Wise
                            House. Dessutom erfarenhet av
                            energilösningar."
                            mail="kjell.lindqvist@realestatesecurity.se"
                            title="CEO/Head of Sales"
                            wholeName="Kjell Lindqvist"
                        />
                        <TeamBox
                            img="/images/jpg/miguel-de-paula.jpg"
                            background="/images/jpg/4to3AR/bandit_dimgenerator.jpg"
                            desc="Min bakgrund finns i fastighetsbranschen
                                        med förvaltning av hyresfastigheter. Jag
                                        har även erfarenhet av event och
                                        utbildning."
                            mail="miguel.depaula@realestatesecurity.se"
                            title="COO"
                            wholeName="Miguel da Paula"
                        />
                        <TeamBox
                            img="/images/jpg/santiago.jpg"
                            background="/images/jpg/4to3AR/bandit_dimgenerator.jpg"
                            desc="I am a Civil engineering from Argentina.
                            My job is to develop new products and
                            work with the market outside Sweden."
                            mail="santiago.paris@realestatesecurity.se"
                            title="CTO"
                            wholeName="Santiago Paris"
                        />
                        <TeamBox
                            img="/images/jpg/placeholder.png"
                            background="images/jpg/4to3AR/_MG_3982-Edit.jpg"
                            desc="Min uppgift är att ansvara och utveckla
                            vår digitala marknadsföring på hemsidor,
                            sociala medier och vår webbshop."
                            mail="filip.dannevik@realestatesecurity.se"
                            title="Digital Media Manager"
                            wholeName="Filip Dannevik"
                        />
                    </div>
                </div>
            </section>

            {/* <!-- ---MORE ABOUT US --- --> */}
            <section className="any-questions">
                <BodyF
                    title1="Om oss"
                    titleLink1="/om-oss"
                    title2="Om företaget"
                    titleLink2="/om-foretaget"
                    title3="Vårt erbjudande"
                    titleLink3="/vart-erbjudande"
                />
            </section>
        </>
    );
};

export default OurTeamPage;
