import React, {useState} from "react";
import Slider from "../../../Slider/Slider";


import CtaBtn1 from "../../../buttons/CtaBtn1/CtaBtn1";
import CtaBtn2 from "../../../buttons/CtaBtn2/CtaBtn2";

import "./head.scss";

import img0 from './wise_house_tagglasare_med-knappsats.jpg';
import img1 from './bandit_dimgenerator.jpg';

const data = [
    {
        id: 0,
        img: "/images/jpg/wise_house_skarm-trapphus_alt1.jpg",
        alt: "",
        loading: "auto"
    },
    {
        id: 1,
        img: img1,
        alt: "",
        loading: "auto"
    },
    {
        id: 2,
        img: img0,
        alt: "",
        loading: "auto"
    },
]

const Head = () => {
    const [swap, setSwap] = useState(0);

    function swapRight() {
        if(swap === data.length-1) {
            setSwap(0);
        } else {
            setSwap(swap + 1);
        }

    }

    function swapLeft() {
        if(swap === 0) {
            setSwap(data.length-1);
        } else {
            setSwap(swap - 1);
        }
    }


    return (
        <section className="head">
            <div className="img">
                <Slider data={data} swap={swap}/>
                <div className="white-cover"></div>
            </div>

            <div className="arrow-left" onClick={swapLeft}>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-left"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    className="fa-chevron-left"
                >
                    <path
                        fill="currentColor"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                    ></path>
                </svg>
            </div>
            <div className="arrow-right" onClick={swapRight}>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    className="fa-chevron-right"
                >
                    <path
                        fill="currentColor"
                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    ></path>
                </svg>
            </div>
            <div className="text-box">
                <div className="slogan-box">
                    <h1>
                        Våra säkerhetslösningar utgår från dina önskemål, inte
                        tekniken!
                    </h1>

                    <p>
                        Vi erbjuder flexibla och skalbara lösningar för din
                        trygghet och säkerhet. Det innebär att vi levererar
                        modern teknik på ett användarvänligt sätt för att skydda
                        din egendom och personer du älskar.
                    </p>
                </div>
                <div className="buttons">
                    <CtaBtn1
                        btnClass="cta-btn1 cta-btn1-var1"
                        link="/sortiment"
                        title="PRODUKTER"
                    />
                    <CtaBtn2 btnClass="cta-btn2 cta-btn2-var1" link="#about-us" href title="Läs mer"/>
                </div>
            </div>

            
        </section>
    );
};

export default Head;
