import React from "react";

import BodyF from '../../body/BodyF';
import Helm from "../../Helm/Helm";

import "./policy.scss";

const Policy = () => {
    return (
        <>
            <Helm
                title={"Policy - Real Estate Security"}
                description={"Vårt mål är att besvara alla frågor inom 12 timmar. Tel: +46 732 09 82 20 från 9-17 vardagar. Mejl: info@realestatesecurity.se."}
                link={"/policy"}
                ogImage
                ogTitle={"Policy - Real Estate Security"}
                ogDescription={"Vårt mål är att besvara alla frågor inom 12 timmar. Tel: +46 732 09 82 20 från 9-17 vardagar. Mejl: info@realestatesecurity.se."}
                author
            />
            <section class="policy-head">
                <div class="img">
                    <img
                        id="img-scale"
                        src="images/jpg/bandit_dimgenerator.jpg"
                        alt="Bandit dimgenerator"
                    />
                </div>
                <div class="title">
                    <h1>Policy</h1>
                </div>
            </section>

            <section class="policy">
                <div>
                    <h4>Integritetspolicy - Real Estate Security Sweden AB</h4>
                    <p>
                        Vi värnar om din integritet. Du ska kunna känna dig
                        trygg när du anförtror oss dina personuppgifter. Därför
                        har vi upprättat den här policyn. Den utgår från
                        gällande dataskyddslagstiftning och förtydligar hur vi
                        jobbar för att ta tillvara dina rättigheter och din
                        integritet.
                    </p>
                    <p>
                        Syftet med den här policyn är att du ska få veta hur vi
                        behandlar dina personuppgifter, vad vi använder dem
                        till, vilka som får ta del av dem och under vilka
                        förutsättningar samt hur du kan ta tillvara dina
                        rättigheter.
                    </p>
                </div>
                <div>
                    <h4>Vi är personuppgiftsansvariga.</h4>
                    <p>
                        Real Estate Security Sweden AB med organisationsnummer
                        559314-0949 och adress Svampskogsvägen 23, 186 55
                        Vallentuna, ansvarar för de personuppgifter som
                        behandlas om dig. Real Estate Security Sweden AB är
                        därför personuppgiftsansvarig för dina personuppgifter.
                    </p>
                </div>
                <div>
                    <h4>Varför behandlar vi personuppgifter om dig?</h4>
                    <p>
                        Vi behandlar dina personuppgifter för att kunna
                        tillhandahålla dig våra tjänster och produkter, dvs.
                        endast för att ingå eller fullgöra ett avtal med dig som
                        kund.
                    </p>
                </div>
                <div>
                    <h4>Vilka uppgifter samlar vi in om dig - och varför?</h4>
                    <p>
                        Vi strävar efter att behandla så få personuppgifter som
                        möjligt om dig. Det innebär att vi inte samlar in fler
                        personuppgifter än nödvändigt för att kunna ingå eller
                        fullgöra avtal med dig.
                    </p>
                    <p>
                        För att kunna ingå samt fullgöra avtal med dig behöver
                        vi information om förnamn, efternamn, e-postadress,
                        adress och andra kontaktuppgifter till dig. Vi får inte
                        tillgång dina personuppgifter på annat sätt än genom
                        ditt tillhandahållande av uppgifterna till oss.
                    </p>
                    <p>
                        Om du inte har givit oss ditt samtycke använder vi inte
                        dina personuppgifter för marknadsföringsändamål.
                    </p>
                </div>
                <div>
                    <h4>Hur skyddar vi dina personuppgifter?</h4>
                    <p>
                        Din säkerhet är viktig för oss. Därför har vi vidtagit
                        lämpliga tekniska, organisatoriska och administrativa
                        säkerhetsåtgärder för att skydda dina personuppgifter
                        från obehörig åtkomst och annan otillåten behandling. Vi
                        analyserar och utvärderar regelbundet åtgärderna i syfte
                        att skyddet för dina uppgifter ska bli så säkert som
                        möjligt.
                    </p>
                </div>
                <div>
                    <h4>Vilka lämnar vi ut dina uppgifter till?</h4>
                    <p>
                        Vi lämnar inte ut dina uppgifter till andra företag
                        eller organisationer om det inte krävs enligt lag eller
                        är nödvändigt för att utföra våra lagstadgade eller
                        avtalsenliga förpliktelser gentemot dig.
                    </p>
                    <p>
                        Vi kan lämna ut dina personuppgifter till någon av våra
                        samarbetspartners, leverantörer eller underleverantörer,
                        men endast om det behövs för att vi ska kunna uppfylla
                        våra skyldigheter i förhållande till dig som kund. Vi
                        lämnar aldrig ut fler personuppgifter än vad som är
                        nödvändigt.
                    </p>
                    <p>
                        När det krävs enligt lag kan vi behöva lämna ut dina
                        uppgifter till myndigheter och andra organisationer. Vi
                        kan också behöva lämna ut dina uppgifter om det är
                        nödvändigt för att utöva, fastställa eller bevaka våra
                        rättsliga anspråk. Vi lämnar aldrig ut dina
                        personuppgifter till andra företag eller verksamheter
                        för marknadsföringsändamål.
                    </p>
                </div>
                <div>
                    <h4>
                        Med stöd av vilka rättsliga grunder behandlar vi
                        personuppgifter om dig?
                    </h4>
                    <p>
                        Vi behandlar endast de personuppgifter vi behöver för
                        att vi ska kunna utföra våra tjänster eller leverera
                        våra produkter till dig, dvs. när vi ingår eller fullgör
                        avtal med dig. I den mån vi behandlar ytterligare
                        uppgifter kommer vi att inhämta ditt samtycke eller
                        säkerställa att behandlingen är tillåten med stöd av
                        annan rättslig grund.
                    </p>
                </div>
                <div>
                    <h4>Hur länge behandlar vi dina personuppgifter?</h4>
                    <p>
                        Vi sparar uppgifter om dig som kund under den tid som
                        avtalet med dig gäller och under en skälig tid därefter.
                        Det innebär i de allra flesta fall att vi inte sparar
                        dina personuppgifter under längre tid än ett år från och
                        med avtalets upphörande, men under vissa förutsättningar
                        kan vi behålla dina uppgifter under en längre tid än så.
                        Det gäller exempelvis när det krävs enligt lag eller när
                        uppgifterna kan behövas för att fastställa, utöva och
                        bevaka rättsliga anspråk.
                    </p>
                </div>
                <div>
                    <h4>Dina rättigheter</h4>
                    <p>
                        När vi behandlar personuppgifter om dig har du som
                        registrerad flera rättigheter. Du har rätt att när som
                        helst ta kontakt med oss gällande dessa, och om du vill
                        utöva någon av rättigheterna som beskrivs nedan når du
                        oss enklast på info@realestatesecurity.se
                    </p>
                    <p>
                        Vi förbehåller oss rätten att vidta lämpliga skydds- och
                        säkerhetsåtgärder i syfte att säkerställa att du är den
                        du utger dig för att vara när du tar kontakt med oss. Om
                        du inte kan visa din identitet på ett trovärdigt sätt är
                        det inte säkert att vi kan tillmötesgå din begäran.
                    </p>

                    <div>
                        <h5>Tillgång till personuppgifter</h5>
                        <p>
                            Du har rätt att få veta vilka personuppgifter vi
                            behandlar om dig. Om du vill veta det kan du få ett
                            sammanställt registerutdrag av oss som innehåller de
                            personuppgifter vi behandlar om dig.
                        </p>
                    </div>
                    <div>
                        <h5>Rättelse och radering</h5>
                        <p>
                            Om vi behandlar dina personuppgifter på ett
                            felaktigt sätt eller om vi inte längre behöver
                            uppgifterna har du rätt att få dem raderade. Om
                            uppgifterna är ofullständiga har du rätt att få dem
                            kompletterade. Tänk på att det inte är säkert att vi
                            kan utföra våra tjänster till dig om du begär att få
                            dina personuppgifter raderade.
                        </p>
                    </div>
                    <div>
                        <h5>Dataportabilitet</h5>
                        <p>
                            Under vissa omständigheter har du rätt att få de
                            uppgifter vi behandlar om dig i ett allmänt,
                            skriftligt, maskinläsbart och strukturerat format.
                            Detta har du rätt till beträffande de
                            personuppgifter som du själv har lämnat till oss och
                            som vi behandlar med stöd av ditt samtycke eller när
                            personuppgifterna krävs för att ingå eller fullföra
                            avtal med dig.
                        </p>
                    </div>
                    <div>
                        <h5>Begränsning av behandling</h5>
                        <p>
                            Under vissa förutsättningar har du rätt att begära
                            att vi ska begränsa vår behandling av dina
                            uppgifter. Det innebär att vi markerar uppgifterna
                            så att vi i framtiden endast behandlar dem för vissa
                            särskilda syften. Det är inte säkert att vi kan
                            tillhandahålla dig våra tjänster om vi begränsar
                            behandlingen av dina personuppgifter.
                        </p>
                    </div>
                    <div>
                        <h5>Rätt att göra invändningar</h5>
                        <p>
                            Du har rätt att invända mot behandling av
                            personuppgifter som utförs i syfte utföra en uppgift
                            av allmänt intresse, som ett led i
                            myndighetsutövning eller efter en intresseavvägning.
                            Vi behandlar inte dina personuppgifter för något av
                            dessa ändamål eller enligt någon av dessa grunder.
                            Du kan därför inte rikta några invändningar mot vår
                            behandling enligt denna grund.
                        </p>
                    </div>
                    <div>
                        <h5>Rätt att inge klagomål</h5>
                        <p>
                            Du har rätt att inge klagomål till
                            Integritetsskyddsmyndigheten om du anser att vi
                            behandlar dina personuppgifter på ett felaktigt
                            sätt. Du kan läsa mer om detta på
                            Integritetsskyddsmyndighetens hemsida
                            www.Integritetsskyddsmyndigheten.se.
                        </p>
                    </div>

                    <h4>Kontakt</h4>
                    <p>
                        Du kan när som helst kontakta oss genom att skriva till
                        info@realestatesecurity.se eller genom att ringa oss på
                        telefonnummer 073-209 82 20 om du vill veta mer om hur
                        vi behandlar dina personuppgifter. Denna
                        integritetspolicy är publicerad på vår hemsida
                        www.realestatesecurity.se
                    </p>
                </div>
            </section>

            <section className="any-questions">
                <BodyF
                    title1="Om företaget"
                    titleLink1="/om-foretaget"
                    title2="Om oss"
                    titleLink2="/om-oss"
                    title3="Vårt team"
                    titleLink3="/vart-erbjudande"
                />
            </section>
        </>
    );
};

export default Policy;
