import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ link, title, price, img, imgAlt, random}) => {
    let moveBack = "";

    if(random) {
        if(Math.random() < 0.5) {
            moveBack = "moveback";
        }
    }

    return (
        <Link to={"/sortiment/" + link} className={"product-list-box " + moveBack}>
            <div className="img">
                <img src={img} alt={imgAlt} />
            </div>
            <hr />
            <div className="bottom-text">
                <h5 className="text-align-left">{title}</h5>
                <h5 className="text-align-right">{price}</h5>
            </div>
        </Link>
    );
};

export default ProductCard;
