import React from "react";
import BodyF from "../../body/BodyF";
import BodyA from "../../body/BodyA";
import BodyB from "../../body/BodyB";
import Helm from "../../Helm/Helm";

import img0 from "./stockholm_omslag.jpg";
import img1 from "./wise_house_tagglasare.jpg";
import img2 from "./Om oss.jpg";

import "./about-us-page.scss";
import AboutUsBox from "../../AboutUsBox/AboutUsBox";

const AboutUsPage = () => {
    return (
        <>
            <Helm
                title={"Om oss - Real Estate Security"}
                description={
                    "Real Estate Security är det flexibla företaget med moderna säkerhetsprodukter."
                }
                link={"/om-oss"}
                ogImage
                ogTitle={"Om oss - Real Estate Security"}
                ogDescription={
                    "Real Estate Security är det flexibla företaget med moderna säkerhetsprodukter."
                }
                author
            />
            <section className="om-oss-page">
                <div className="om-oss-page-box">
                    <AboutUsBox page="1" />
                    <div className="om-oss-page-box-bottom-special-blend">
                        <h1>Om oss</h1>
                        <hr />
                    </div>
                </div>
            </section>

            <section className="about-us" id="about-us">
                <BodyA
                    img={img0}
                    title="Om företaget"
                    desc="Vi är ett ungt och entreprenaddrivet företag i fastighets- och säkerhetsbranschen som arbetar för att öka din trygghet genom att förstärka säkerheten i ditt boende eller på din arbetsplats."
                    link="/om-foretaget"
                    btnTitle="Läs mer"
                />
                <BodyB
                    img={img1}
                    title="Vårt erbjudande"
                    desc="Vi erbjuder tjänster som skall förhindra inbrott, vandalisering, rån, kriminell verksamhet och skadegörelse, genom att erbjuda marknadens vassaste produkter från utvalda leverantörer."
                    link="/vart-erbjudande"
                    btnTitle="Läs mer"
                />
                <BodyA
                    img={img2}
                    title="Vårt team"
                    desc="Vårt team är en den perfekta mixen. Vi blandar
                    gedigen erfarenhet från branschen med nytänkande
                    och ungdomlig energi."
                    link="/vart-team"
                    btnTitle="Läs mer"
                />
            </section>

            {/* <!-- ---MORE ABOUT US --- --> */}
            <section className="any-questions">
                <BodyF
                    title1="Om företaget"
                    titleLink1="/om-foretaget"
                    title2="Vårt erbjudande"
                    titleLink2="/vart-erbjudande"
                    title3="Vårt team"
                    titleLink3="/vart-team"
                />
            </section>
        </>
    );
};

export default AboutUsPage;
