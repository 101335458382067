import React from "react";
import AboutUsBox from "../../AboutUsBox/AboutUsBox";
import Helm from "../../Helm/Helm";

import "./about-company-page.scss";

import BodyH from "../../body/BodyH";
import BodyA from "../../body/BodyA";
import BodyB from "../../body/BodyB";
import BodyF from "../../body/BodyF";

const AboutCompanyPage = () => {
    return (
        <>
            <Helm
                title={"Om företaget - Real Estate Security"}
                description={
                    "Läs mer om vår affärsverksamhet och företaget; Real Estate Security är det flexibla företaget med moderna säkerhetsprodukter."
                }
                link={"/om-foretaget"}
                ogImage
                ogTitle={"Om företaget - Real Estate Security"}
                ogDescription={
                    "Läs mer om vår affärsverksamhet och företaget; Real Estate Security är det flexibla företaget med moderna säkerhetsprodukter."
                }
                author
            />
            <section className="om-oss-page">
                <div className="om-oss-page-box">
                    <AboutUsBox page="2"/>
                    <div className="om-oss-page-box-bottom">
                        <img src="images/jpg/stockholm_omslag.jpg" alt="" />
                        <div className="title-var1">
                            <h1>Om företaget</h1>
                            <div className="p">
                                <p>
                                    Vi är ett ungt och entreprenaddrivet företag
                                    i fastighets- och säkerhetsbranschen som
                                    arbetar för att öka din trygghet genom att
                                    öka säkerheten i ditt boende eller på din
                                    arbetsplats.
                                </p>
                            </div>
                            <div className="p">
                                <p>
                                    Vi levererar våra tjänster och produkter i{" "}
                                    <strong>hela Sverige.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-us" id="about-us">
                <BodyA
                    img="/images/jpg/pedestrians_var_vision.jpg"
                    title="Vår vision"
                    desc="Ett av våra mål är att i så stor utsträckning
                    som möjligt sätta stopp för inbrott,
                    vandalisering, rån, kriminell verksamhet och
                    skadegörelse, genom att erbjuda marknadens
                    vassaste produkter från utvalda leverantörer."
                    link="/om-foretaget"
                    btnTitle="Läs mer"
                />
                <BodyB
                    img="images/jpg/var_målgrupp.jpg"
                    title="Målgrupp"
                    desc="Vi vänder oss till alla fastighetsägare och
                    bostadsrättsföreningar, byggherrar och
                    fastighetsförvaltare. Men även till företagare
                    med till exempel kontor eller butik,
                    organisationer och privatpersoner."
                    link="/vart-erbjudande"
                    btnTitle="Läs mer"
                />
                <BodyH/>
            </section>

            {/* <!-- ---MORE ABOUT US --- --> */}
            <section className="any-questions">
                <BodyF
                    title1="Om oss"
                    titleLink1="/om-oss"
                    title2="Vårt erbjudande"
                    titleLink2="/vart-erbjudande"
                    title3="Vårt team"
                    titleLink3="/vart-team"
                />
            </section>
        </>
    );
};

export default AboutCompanyPage;
