import React from 'react'
import { Link } from 'react-router-dom';
import MenuItem from '../MenuItem/MenuItem'
import logoShortText from '../logo_shorttext.png';
import MenuButton from '../MobileMenuBar/MenuButton/MenuButton';


const DesktopMenuBar = ({toggleMenu, open, scroll}) => {
    return (
        <div className="header-box" style={{backgroundColor: `rgba(255,255,255,${scroll}`}}>
            <div className="header-box-width">
                <nav>
                    <ul className="left">
                        <MenuItem className="fill2" link="/sortiment" title="Produkter"/>
                        <MenuItem className="fill4 samarbetspartners" link="/samarbetspartners" title="Samarbetspartners"/>
                    </ul>
                    <div className="logo">
                        <picture>
                            <Link to="/">
                                <img src={logoShortText} loading="auto" alt="" />
                            </Link>
                        </picture>
                    </div>
                    <ul className="right">
                        <MenuItem className="fill2 media" link="/media" title="Media"/>
                        <MenuItem className="fill2 om-oss" link="/om-oss" title="Om oss"/>
                        <MenuItem className="fill2 kontakt" link="/kontakt" title="Kontakt"/>
                    </ul>
                    <MenuButton toggleMenu={toggleMenu} open={open}/>
                </nav>
            </div>
            
        </div>
    )
}

export default DesktopMenuBar
