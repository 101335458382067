import React from "react";

import "./body-h.scss";

const BodyH = () => {
    return (
        <div className="body-h-body">
            <div className="body-h-body-title">
                <h2>Vad vi värdesätter</h2>
            </div>
            <div className="body-h">
                <div className="body-h-col">
                    <div className="img">
                        <img src="/images/jpg/Pro Dome-01 (1).png" alt="" />
                        <h3>Trygghet</h3>
                    </div>
                    <div className="bottomtext">
                        <p>
                            Med våra produkter vill vi att du som person eller
                            företagare skall känna:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    trygghet i att du har rätt
                                    säkerhetsprodukter.
                                </p>
                            </li>
                            <li>
                                <p>trygghet i att du har modern teknik.</p>
                            </li>
                            <li>
                                <p>anpassningsbar för dina behov.</p>
                            </li>
                        </ul>
                        <p>
                            Det gäller oavsett om du är hemma, utomhus eller på
                            ditt jobb.
                        </p>
                    </div>
                </div>
                <div className="body-h-col">
                    <div className="img">
                        <img
                            src="/images/jpg/1to1AR/1-1_MG_3982-Edit.jpg"
                            alt=""
                        />
                        <h3>Säkerhet</h3>
                    </div>
                    <div className="bottomtext">
                        <p>
                            För oss betyder säkerhet att vi skyddar inte bara
                            skyddar personer utan även de saker som betyder mest
                            för dig.
                            <br />
                            Det kan vara din bostad, bil, MC, tavlor,
                            accessoarer och mycket annat.{" "}
                        </p>

                        <p>
                            Du kanske till och med funderar på att installera
                            ett sk “Safty-Room”? Då har vi kunskapen och
                            produkterna för dig.
                        </p>
                    </div>
                </div>
                <div className="body-h-col">
                    <div className="img">
                        <img
                            src="/images/jpg/1to1AR/1-1_wise_house_skarm-trapphus_alt1.jpg"
                            alt=""
                        />
                        <h3>Simplicitet</h3>
                    </div>
                    <div className="bottomtext">
                        <p>
                            När vi väljer levernatörer vill ha också ha
                            produkter som är har en simplicitet, dvs de skall
                            vara:
                        </p>
                        <ul>
                            <li>
                                <p>lätt att använda/installera</p>
                            </li>
                            <li>
                                <p>lätt att sammanlänka</p>
                            </li>
                            <li>
                                <p>lätt att uppgradera</p>
                            </li>
                        </ul>
                        <p>
                            Kort sagt....tekniken skall vara vara på användarens
                            villkor, inte tvärtom.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BodyH;
