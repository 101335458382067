import React from "react";

import logoLongText from "./logo_longtext.png";
import twitterIcon from "./asdjfja.png";
import instagramIcon from "./askldjf.png";
import fbIcon from "./facebooefk_logo.png";
import linkedInIcon from "./LinkedIn_logo_initials.png";
import { Link } from "react-router-dom";

import "./footer.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-line">
                <hr />
            </div>
            <div className="footer-body">
                <div className="footer-body-left">
                    <img src="/RES Logo black long text swedish flag 256.png" alt="RESS LOGO" loading="lazy" />
                </div>
                <div className="footer-body-right">
                    <div className="footer-body-right-col">
                        <Link to="/sortiment">
                            <h6>Produkter</h6>
                        </Link>
                        <ul>
                            <Link to="/sortiment/trygghetsspray">
                                <li>SMART Trygghetsspray</li>
                            </Link>
                            <Link to="/sortiment/overvakningskameror">
                                <li>Övervakningskameror</li>
                            </Link>
                            <Link to="/sortiment/inbrottsskyddande-glas">
                                <li>Inbrottsskyddande glas</li>
                            </Link>
                            <Link to="/sortiment/digitala-tjanster">
                                <li>Digitala fastighetstjänster</li>
                            </Link>
                            <Link to="/sortiment/dimgeneratorer">
                                <li>Dimgeneratorer</li>
                            </Link>
                            <Link to="/sortiment/sakerhetsdorrar">
                                <li>Säkerhetsdörrar</li>
                            </Link>
                        </ul>
                    </div>
                    <div className="footer-body-right-col">
                        <Link to="/om-oss">
                            <h6>Om oss</h6>
                        </Link>
                        <ul>
                            <Link to="/om-foretaget">
                                <li>Om företaget</li>
                            </Link>
                            <Link to="/vart-team">
                                <li>Vårt team</li>
                            </Link>
                            <Link to="/vart-erbjudande">
                                <li>Vårt erbjudande</li>
                            </Link>
                            <Link to="/samarbetspartners">
                                <li>Samarbetspartners</li>
                            </Link>
                        </ul>
                    </div>
                    <div className="footer-body-right-col">
                        <Link to="/">
                            <h6>Real Estate Security</h6>
                        </Link>
                        <ul>
                            <Link to="/kontakt">
                                <li>Kontakta oss</li>
                            </Link>
                            <Link to="/om-oss">
                                <li>Om oss</li>
                            </Link>
                            <Link to="/media">
                                <li>Media</li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-follow-us">
                <div>
                    <div className="text">
                        <p>Följ oss: </p>
                    </div>
                    <Link to="">
                        <img
                            src={twitterIcon}
                            alt="Twitter"
                            style={{ width: "44px", height: "44px" }}
                            loading="lazy"
                        />
                    </Link>
                    <Link to="">
                        <img
                            src={instagramIcon}
                            alt=""
                            style={{ width: "44px", height: "44px" }}
                            loading="lazy"
                        />
                    </Link>
                    <a href="https://www.facebook.com/Real-Estate-Security-102651352118519/">
                        <img
                            src={fbIcon}
                            alt=""
                            style={{ width: "44px", height: "44px" }}
                            loading="lazy"
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/real-estate-security-sweden-ab/">
                        <img
                            src={linkedInIcon}
                            alt=""
                            style={{ width: "44px", height: "44px" }}
                            loading="lazy"
                        />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="text">
                    <p>© Real Estate Security, AB. 2021</p>
                    <p> • </p>
                    <Link to="/policy">
                        <p>Policy</p>
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
