import React from 'react'
import CtaBtn2 from '../buttons/CtaBtn2/CtaBtn2'
import CtaBtn1 from '../buttons/CtaBtn1/CtaBtn1'

import './body-c.scss';
import './body.scss';

const BodyC = ({img, icon1, icon2}) => {
    return (
        <div className="body-c-body">
            <div className="body-c-title">
                <h2>Vår kampanjprodukt</h2>
            </div>
            <div className="body-c">
                <div className="body-c-left">
                    <img src={img} alt="" loading="lazy"/>
                </div>
                <div className="body-c-right">
                    <h4>Övervakningskameror</h4>
                    <p>Vi erbjuder en knivskarp kameraövervakning, med en av marknadens bästa upplösningar (upp till 12MP). Produkterna har AI-teknik, vilket effektiviserar övervakningen.</p>
                    <ul>
                        <li>
                            <img src={icon1} alt="" loading="lazy"/>
                            <p>180 grader</p>
                        </li>
                        <li>
                            <img src={icon2} alt="" loading="lazy"/>
                            <p>360 grader</p>
                        </li>
                    </ul>
                    <div className="buttons">
                        <CtaBtn1 btnClass="cta-btn1 cta-btn1-var2" link="/produkter/overvakningskameror" title="Läs mer"/>
                        <CtaBtn2 btnClass="cta-btn2 cta-btn2-var2" link="mailto:offert@realestatesecurity.se?Subject=Offert - Övervakningskameror" title="Skicka offert"/>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default BodyC
