import React from "react";
import CtaBtn1 from "../buttons/CtaBtn1/CtaBtn1";

import Icons from "../Icons/Icons";

import './body-f.scss';
import './body.scss';

const BodyF = ({title1, title2, title3, titleLink1, titleLink2, titleLink3}) => {
    return (
        <div className="body-f-body">
            <div className="body-f">
                <div>
                    <h3>Har du frågor?</h3>
                    <div className="btn-center">
                        <CtaBtn1 btnClass="cta-btn1 cta-btn-nospace" link={titleLink1} title={title1} />

                        {title2 && <CtaBtn1 btnClass="cta-btn1 cta-btn-nospace" link={titleLink2} title={title2} />}

                        {title3 && <CtaBtn1 btnClass="cta-btn1 cta-btn-nospace" link={titleLink3} title={title3} />}

                    </div>

                    <Icons/>
                </div>
            </div>
        </div>
    );
};

export default BodyF;
