const products = [
    {
        id: 0,
        title: "SMART Trygghetsspray - skydda dig och dina egendomar",
        desc: "Vår SMART Trygghetsspray är en laglig och kraftigt ögonirriterande mentolbaserad försvarsspray med röd färg, kraftfull siren, LED-lampor som blinkar samt gratis automatiska positions-SMS och telefonsamtal till dina nödkontakter.",
        price: "399 kr",
        link: "trygghetsspray",
        squareImg: "/images/jpg/trygghetsspray-front.jpg",
        images: [
            {
                id: "0",
                img: "/images/jpg/trygghetsspray-front.jpg",
                alt: "Trygghetsspray front"
            },
            {
                id: "1",
                img: "/images/jpg/trygghetsspray_phone_kvad.jpg",
                alt: "Trygghetsspray phone"
            },
            {
                id: "2",
                img: "/images/jpg/trygghetsspray-front.jpg",
                alt: "Trygghetsspray front"
            },
            {
                id: "3",
                img: "/images/jpg/trygghetsspray_flash_kvad.jpg",
                alt: "Trygghetsspray blixt"
            },
        ],
        specs: [
            {
                id: 0,
                title: "Trygghetsspray med mentol och röd färg",
            },
            {
                id: 1,
                title: "Positions-SMS och Telefonsamtal (gratis)",
            },
            {
                id: 2,
                title: "Siren",
            },
            {
                id: 3,
                title: "LED-lampor",
            },
            {
                id: 4,
                title: "Magnetisk delbar nyckelkedja",
            },
            {
                id: 5,
                title: "4 års laddfri batteritid",
            },
        ],
        pdf: [
        ],
        extra: [
            {
                id: 0,
                title: "funktion 1"
            },
            {
                id: 1,
                title: "funktion 2"
            }
        ],
        longDesc: ["%St%Trygghetsspray med mentol och röd färg", "Alla Plegium försvarssprayer innehåller en vätska baserad på högkoncentrerad mentol, vilket är lagligt och licensfritt för privatpersoner i Sverige.", "Riktig pepparspray är olagligt för privatpersoner i Sverige.", "Mentol är kraftigt ögonirriterande och förblindar tillfälligt angriparen så att du kan sätta dig i säkerhet.", "Sprayen innehåller också ett rött färgämne som är mycket svårt att tvätta bort, vilket underlättar identifiering av gärningsmannen.", "Våra försvarssprayer har testats av personer som också har testat att bli sprayade med pepparspray, och de uppger att vår formula med mentol har en förblindande effekt likvärdig pepparspray.", "Alla Plegium har en räckvidd på över 3 meter och ett innehåll tillräckligt för många gärningsmän.", "%St%Positions-SMS och telefonsamtal", "Plegium är de enda försvarssprayerna i världen som automatiskt skickar SMS med din position samt ringer upp din anhöriga när du avfyrar sprayen, så att du kan få hjälp snabbare.", "Modellerna Smart och Smart Mini parkopplar automatiskt via Bluetooth till din mobil. I appen Plegium - för iOS och Android - lägger du in upp till 5 nödkontakter.", "Plegium-appen är helt gratis - ingen månadskostnad - och vi står för kostnaden för alla SMS och telefonsamtal.", "%St%Siren", "Modellerna Smart och Combo har en kraftfull inbyggd siren, för att påkalla uppmärksamhet.", "Sirenen tjuter starkare än något annat fristående överfallslarm på marknaden.", "Dessutom har tjutet stämts för att resonera i frekvenser där den mänskliga hörseln är som mest känslig."]
    },
    {
        id: 1,
        title: "Inbrottsskyddande glas",
        desc: "Det krossäkra glaset vi erbjuder är  uppemot 300 gånger starkare än ett vanligt glas! Glaset klarar försök med kofot, yxa, tyngder - utan problem!",
        price: "Skicka offert",
        link: "inbrottsskyddande-glas",
        squareImg: "/images/jpg/inbrottsskyddande_glas.jpg",
        images: [
            {
                id: "0",
                img: "/images/jpg/inbrottsskyddande_glas.jpg",
                alt: "Inbrottsskyddande glas"
            },
            {
                id: "1",
                img: "/images/jpg/placeholder.png",
                alt: "Placeholder"
            },
        ],
        specs: [
            {
                id: 0,
                title: "Glaset är uppemot 300 gånger starkare än ett vanligt glas!",
            },
            {
                id: 1,
                title: "Försök med kofot, yxa, tyngder – klarar detta glas av utan problem! Låt oss få visa det för er!",
            },
        ],
        pdf: [
            {
                id: 0,
                title: "Abrasion-resistant solid polycarbonate sheet",
                href: "/produktblad/inbrottsskyddande-glas/Makrolon AR Produktdatablad.pdf"
            },
            {
                id: 1,
                title: "Solid polycarbonate sheet",
                href: "/produktblad/inbrottsskyddande-glas/mf0112_e_200320.pdf"
            },
            {
                id: 2,
                title: "P3895__Schueco_Einbruch_Durchschusshemmung",
                href: "/produktblad/inbrottsskyddande-glas/P3895__Schueco_Einbruch_Durchschusshemmung__12_2013__de_en.pdf"
            },
            {
                id: 3,
                title: "P3896__Schueco_Sprengwirkungshemmung",
                href: "/produktblad/inbrottsskyddande-glas/P3896__Schueco_Sprengwirkungshemmung__08_2011__de_en.pdf"
            },
        ],
        extra: [

        ],
        longDesc: ["Det krossäkra glaset som vi har i vårt sortiment är framtaget för att stå emot ett starkt yttre tryck!", "Vi har i samarbete med några av marknadens vassaste aktör inlett en kamp för att beivra brott och stävja brottsutvecklingen. Vi vill att folk skall känna sig trygga – på riktigt!", "Riktig pepparspray är olagligt för privatpersoner i Sverige.", "Glaset finns i olika skyddsklasser som är lätta att anpassa till byggnadens olika strukturer, ålder med mera. Glaset är tåligt men också tunt och väger inte mycket. glaset kan bytas i antingen den yttre eller den inre rutan. montaget som sådant för att installera ett inbrottsskyddande Glaset har ingen påverkan på byggnadens arktiktektur.", "Andra fördelar med glaset är att det är energieffektivt. Det krossäkra glaset ger viktiga u-värdesförbättringar och en ljudreduktion om 30 dbrw."]
    },
    {
        id: 2,
        title: "Säkerhetsdörrar",
        desc: "Gällande säkerhetsdörrar erbjuder vi produkter med Svensk eller Italiensk kvalité och design. Alltid med högsta kraven på säkerhet i Sverige. I vårt ortiment finns även entréportar.",
        price: "Skicka offert",
        link: "sakerhetsdorrar",
        squareImg: "/images/jpg/1to1AR/1-1_MG_3978-Edit.jpg",
        images: [
            {
                id: "0",
                img: "/images/jpg/_MG_3978-Edit.jpg",
                alt: "Säkerhetsdörr"
            },
            {
                id: "1",
                img: "/images/jpg/_MG_2302.jpg",
                alt: "Säkerhetsdörr"
            },
            {
                id: "2",
                img: "/images/jpg/_MG_3982-Edit.jpg",
                alt: "Säkerhetsdörr"
            },
            {
                id: "3",
                img: "/images/jpg/klassisk_säkerhetsdörr.jpg",
                alt: "Klassisk säkerhetsdörr"
            }, 
        ],
        pdf: [
            {
                id: 0,
                title: "Produktblad STANDARDDÖRRAR",
                href: "/produktblad/sakerhetsdorrar/Woodsteel - Torterolo/WS3_broschyr_w.pdf"
            },
            {
                id: 1,
                title: "Produktblad SPECIALDÖRRAR",
                href: "/produktblad/sakerhetsdorrar/Woodsteel - Torterolo/referensbroschyr_w.pdf"
            }
        ],
        extra: [
            {
                id: 0,
                title: "funktion 1"
            },
            {
                id: 1,
                title: "funktion 2"
            }
        ],
        longDesc: ["”Kvalité, design och trygghet i hemmet”", "Vi har idag ett stort problem med inbrott i både villor, lägenheter och kontor. Detta problem är stort runtom i Sverige. Att bli drabbad av att obehöriga har varit i ens hem eller sitt kontor är en obeskrivlig känsla som slår direkt mot privatlivet.", "Av den anledningen har vi utvecklat ett samarbete med två leverantörer av säkerhetsdörrar. Vi erbjuder Svensk kvalité och Italiensk design, båda fyller de högsta kraven på säkerhet, i Sverige. ", "Utbudet består av såväl lägenhetsdörrar som källar-och förrådsdörrar. Alla dörrar är testade och godkända enligt RISE.SE.", "TEC Woodsteel AB - är en av de största tillverkarna av säkerhetsdörrar i Europa. De erbjuder en kombination av snygg och trevlig design med hög säkerhet."]
    },
    {
        id: 3,
        title: "Övervakningskameror",
        desc: "Vi erbjuder en knivskarp kameraövervakning, med en av marknadens bästa upplösningar (upp till 12MP) . Produkterna har AI-teknik, vilket effektiviserar övervakningen.",
        price: "Skicka offert",
        link: "overvakningskameror",
        squareImg: "/images/jpg/1to1AR/1-1_overvakningskameror_fisheye-02.png",
        images: [
            {
                id: "0",
                img: "/images/jpg/overvakningskameror_fisheye-02.png",
                alt: "Övervakningskamera"
            },
            {
                id: "1",
                img: "/images/jpg/Pro Dome-05.png",
                alt: "Pro Dome 5 Övervakningskamera"
            },
            {
                id: "2",
                img: "/images/jpg/180° Panoramic Mini Dome-03.png",
                alt: "180 Panoramisk mini Dome 3 Övervakningskamera"
            },
            {
                id: "3",
                img: "/images/jpg/overvakat_omrade.png",
                alt: "Skylt övervakningskamera"
            },
        ],
        pdf: [
            {
                id: 0,
                title: "180° Panoramic H.265 Mini Dome Network Camera",
                href: "/produktblad/overvakningskameror/Milesight-180°-Panoramic-H.265-Mini-Dome-Network-Camera-Datasheet-en.pdf"
            },
            {
                id: 1,
                title: "360° Panoramic H.265 Fisheye Network Camera",
                href: "/produktblad/overvakningskameror/Milesight-360°-Panoramic-H.265-Fisheye-Network-Camera-Datasheet-en.pdf"
            },
            {
                id: 2,
                title: "H.265 Motorized Pro Dome Network Camera",
                href: "/produktblad/overvakningskameror/Milesight-H.265-Motorized-Pro-Dome-Network-Camera-Datasheet-en.pdf"
            }
        ],
        extra: [
            {
                id: 0,
                title: "funktion 1"
            },
            {
                id: 1,
                title: "funktion 2"
            }
        ],
        longDesc: ["Några vanliga situationer i en fastighet idag är källarinbrott, inbrott i företagslokaler, bilinbrott i garage, kriminell försäljning och missförhållanden i miljörum (soprum).", "Känner du igen dig, eller har du andra situationer du gärna vill få kontroll över?", "Vi erbjuder en knivskarp kameraövervakning, med en av marknadens bästa upplösningar som ger dig detaljrik information, som du kan använda dig av.", "Inom ramen för GDPR erbjuder vi anpassade lösningar för hur informationen kan hanteras samt framtida service-och underhåll.", "Integritetsmyndigheten, IMY, har också lättat på reglerna gällande bostadsrättsföreningar som vill sätta upp övervakningskameror. Kontakta oss så berättar vi mer."]
    },
    {
        id: 4,
        title: "Smarta Digitala Fastighetstjänster – tryggt och enkelt!",
        desc: "Vi vill vara det självklara valet för er när det gäller digitala och smarta fastighetstjänster. Det vi erbjuder är flexibla och skalbara lösningar där vi tillsammans anpassar tjänsterna efter era behov. Skalbarheten gör att ni enkelt kan bygga ut och komplettera era fastighetstjänster utan att behöva köpa allt nytt eller att ha flera leverantörer.",
        price: "Skicka offert",
        link: "digitala-tjanster",
        squareImg: "/images/jpg/1to1AR/1-1_wise_house_skarm-tvattstuga (1).jpg",
        images: [
            {
                id: "0",
                img: "/images/jpg/wise_house_skarm-tvattstuga (1).jpg",
                alt: "Tvättstyga smart digital skärm"
            },
            {
                id: "1",
                img: "/images/jpg/kontakt_porttelefon.jpg",
                alt: "Smärtskärm porttelefon"
            },
            {
                id: "2",
                img: "/images/jpg/wise_house_tagg.png",
                alt: "Smartskärmstagg"
            },
            {
                id: "3",
                img: "/images/jpg/wise_house_skarm-trapphus_alt1.jpg",
                alt: "Smartskärm till trapphus och lägenheter"
            },
        ],
        specs: [
            {
                id: 0,
                title: "Passagesystem",
            },
            {
                id: 1,
                title: "Informations- och bokningssystem",
            },
            {
                id: 2,
                title: "Övervakningskameror",
            },
            {
                id: 3,
                title: "Inbrotts-och Brandlarm",
            },
            {
                id: 4,
                title: "Överfalls- trygghetslarm",
            },
            {
                id: 5,
                title: "Övervakning av UC",
            },
            {
                id: 6,
                title: "Fuktmätning och felanmälan",
            },
            {
                id: 7,
                title: "IMD (individuell mätning och debitering)... och mycket mer",
            },
        ],
        pdf: [
            {
                id: 0,
                title: "Bokningssystem",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_bokningssystem1.pdf"
            },
            {
                id: 1,
                title: "Informationssystem",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_informationssystem1.pdf"
            },
            {
                id: 2,
                title: "Lägenhetsskärm 10\"",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_lagenhetsskarm1.pdf"
            },
            {
                id: 3,
                title: "Passersystem",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_passersystem1.pdf"
            },
            {
                id: 4,
                title: "Porttelefon med taggläsare",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_porttelefon-med-tagglasare1.pdf"
            },
            {
                id: 5,
                title: "Kodlås/taggläsare med knappsats",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_tagglasare-med-knappsats1.pdf"
            },
            {
                id: 6,
                title: "Trapphusskärmar 22\" och 32\"",
                href: "/produktblad/digitala-fastighetstjanster/wise-house_trapphusskarmar1.pdf"
            }
        ],
        extra: [

        ],
        longDesc: ["Vi väljer att arbeta med IP-baserade plattformar, det gör att alla boende i en fastighet på ett smidigt sätt för tillgång till olika smarta hem-lösningar. ", "%Br%Exempel; Du behöver inte oroa dig för att inte hinna tvätta, innan du skall åka vidare bara någon dag efter att du kommit hem. Du kan lugnt boka din tvättid via dator eller mobil direkt från solstolen på Gran Canaria. ", "Till dig som är fastighetsägare/styrelse i Brf, ger vi ett verktyg för att effektivisera delar av verksamheten, förbättra kommunikationen med de boende, spara på miljön och dessutom kan du själv sköta stora delar av det administrativa arbetet från kontoret, hemmet, landstället eller när du är utomlands. Vill du ha hjälp med att sköta administrationen så kan vi ordna med det också." , "En av de plattformar vi arbetar med heter Wise House:" ,"Wise House erbjuder en webbaserad tjänst för digitalisering av fastigheter som förenklar skickande och mottagande av information, inpassering, bokningar etc.", "Administratören ges stora valfriheter vid utformning och anpassning, t.ex kan information specificeras för en särskilt fastighet/område/person.", "All information från Wise House kan både läsas och administreras på alla typer av datorer, mobiltelefoner och surfplattor. Det enda som behövs är en inloggning."]
    },
    {
        id: 5,
        title: "Dimgeneratorer",
        desc: "Med en dimgerator skapar du ett fantastiskt bra skydd för din personal, ditt företag och din bostad. Tänk på att det varje dag rånas minst en butik i Sverige, varje dag sker inbrott i lager och bostäder som innehåller dyrbar utrustning.",
        price: "Skicka offert",
        link: "dimgeneratorer",
        squareImg: "/images/jpg/1to1AR/1-1_bandit_dimgenerator.jpg",
        images: [
            {
                id: "0",
                img: "../images/jpg/bandit_dimgenerator.jpg",
                alt: "Dimgenerator Bandit"
            },
            {
                id: "1",
                img: "/images/jpg/dimgenerator_vit.jpg",
                alt: "Vit dimgenerator"
            },
            {
                id: "2",
                img: "/images/jpg/dimgeneratorer_dimma.jpg",
                alt: "Dimgenerator dimma"
            },
            {
                id: "3",
                img: "/images/jpg/1to1AR/1-1_bandit_dimgenerator.jpg",
                alt: "Bandit dimgenerator"
            },
            
        ],
        pdf: [
            {
                id: 0,
                title: "Bandit 320",
                href: "/produktblad/dimgeneratorer/BANDIT-320.pdf"
            },
        ],
        extra: [
            {
                id: 0,
                title: "funktion 1"
            },
            {
                id: 1,
                title: "funktion 2"
            }
        ],
        longDesc: ["Genom att investera i en dimgenerator får du ett effektivt skydd som förhindrar dessa tillgrepp på ett snabbt och säkert sätt. ", "Bandit är världsledande på denna teknik. En dimgenerator genererar upp till 50 m3 dimma per sekund. Dimman gör att det inom några sekunder fyller rummet och det går inte att se igenom den.", "Dimman är ofarlig för hälsan, men omöjlig att se igenom.", "Bandit är rekommenderas av Svensk Handel."]
    },
];

export default products;
