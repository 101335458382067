import React from "react";
import CtaBtn3 from "../buttons/CtaBtn3/CtaBtn3";

import './body-e.scss';
import './body.scss';

const BodyE = ({ img1, img2 }) => {
    return (
        <div className="body-e-body">
            <div className="body-e-title">
                <div className="body-e-title-box">
                    <h2>Lär känna oss</h2>
                    <p>
                        Vi som arbetar i Real Estate Security har en mix av
                        erfarenhet och nytänkande. Vi anser att det ger dig som
                        kund den trygghet du förväntar dig när det gäller
                        säkerhetsfrågor. Våra fantastiska samarbetspartner har
                        vi valt för att de har marknadsledande produkter och
                        erbjuder flexibla lösningar.
                    </p>
                </div>
            </div>
            <div className="body-e">
                <div className="body-e-col">
                    <img src={img1} alt="" loading="lazy" />
                    <div className="text-col">
                        <h4>Samarbetspartner</h4>
                        <p>
                            Vi har omsorgsfullt valt våra samarbetspartners för
                            att erbjuda er bästa produkterna för trygghet och
                            säkerhet. Här kan du läsa mer om dem.
                        </p>
                    </div>
                    <CtaBtn3 link="samarbetspartners" title="Läs mer" />
                </div>
                <div className="body-e-col">
                    <img src={img2} alt="" loading="lazy" />
                    <div className="text-col">
                        <h4>Vårt team</h4>
                        <p>
                            Här finner du kontaktuppgifter till alla oss som
                            arbetar i Real Estate Security Sweden AB.
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>

                    <CtaBtn3 link="vart-team" title="Läs mer" />
                </div>
            </div>
        </div>
    );
};

export default BodyE;
