import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import CtaBtn1 from "../../buttons/CtaBtn1/CtaBtn1";
import ProductCards from "../../ProductCards/ProductCards";
import BodyF from "../../body/BodyF";
import PhotoOpenOverlay from "../../PhotoOpenOverlay/PhotoOpenOverlay";
import Helm from "../../Helm/Helm";

import products from "../../../data/products/products";

import "./produkt.scss";

var title;

const ProductPage = () => {
    // OPENS THE RIGHT EVENT
    let { productId } = useParams();
    let navigate = useNavigate();

    let hasError = false;
    var productLinks = products.map((e) => e.link);
    var productIndex = productLinks.findIndex((e) => e === productId);

    if (productIndex === -1) {
        productIndex = 0;
        hasError = true;
    }

    useEffect(() => {
        if (hasError) {
            navigate("/sortiment");
        }
    });

    //Image change
    const [imgIndex, setImgIndex] = useState(0);

    function changeImage(imageIndex) {
        setImgIndex(imageIndex);
    }

    function moveUp() {
        imgIndex > 0
            ? setImgIndex((prevState) => prevState - 1)
            : setImgIndex(products[productIndex].images.length - 1);
    }
    function moveDown() {
        imgIndex + 1 < products[productIndex].images.length
            ? setImgIndex((prevState) => prevState + 1)
            : setImgIndex(0);
    }

    //ARROWS
    const upArrow = (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="sort-up"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            className="fa-sort-up"
        >
            <path
                fill="currentColor"
                d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
            ></path>
        </svg>
    );

    const downArrow = (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="sort-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            className="fa-sort-down"
        >
            <path
                fill="currentColor"
                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
            ></path>
        </svg>
    );

    // OVERLAY
    const [overlayOpen, setOverlayOpen] = useState(false);
    function toggleOverlay() {
        setOverlayOpen(!overlayOpen);
    }

    return (
        <>
            <Helm
                title={products[productIndex].title + " / Produkter - Real Estate Security"}
                description={products[productIndex].description}
                link={"/sortiment/" + products[productIndex].link}
                ogImage
                ogTitle={products[productIndex].title}
                ogDescription={products[productIndex].description}
                author
            />
            {overlayOpen && (
                <section className="overlay">
                    <PhotoOpenOverlay
                        image={products[productIndex].images[imgIndex].img}
                        alt={products[productIndex].images[imgIndex].alt}
                        title={products[productIndex].images[imgIndex].alt}
                        open={toggleOverlay}
                    />
                </section>
            )}
            <section className="product-page">
                <div className="main">
                    <div className="left" id="subheadMenu">
                        <ul>
                            <div
                                className="mobile-swipe-arrow"
                                id="mobile-swipe-arrow"
                            >
                                <i className="fas fa-chevron-right"></i>
                            </div>
                            {products.map((product) => (
                                <Link
                                    to={"/sortiment/" + product.link}
                                    key={product.id}
                                    onClick={() => setImgIndex(0)}
                                >
                                    <li>
                                        <p>{product.title}</p>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className="right">
                        <div className="head-img">
                            <img
                                src={products[productIndex].images[imgIndex].img}
                                alt={products[productIndex].images[imgIndex].alt}
                                onClick={() => toggleOverlay()}
                                id="mainImg"
                            />
                            <div className="head-img-title-box"></div>
                            <div className="head-img-slider">
                                <div
                                    className="head-img-slider-box"
                                    onClick={() => moveUp()}
                                >
                                    {upArrow}
                                    {/* {console.log(
                                            (imgIndex === 1) +
                                                imgIndex +
                                                "," +
                                                1
                                        )} */}
                                </div>
                                {products[productIndex].images.map((img) => (
                                    <div
                                        key={img.id}
                                        className="head-img-slider-box"
                                        onClick={() => changeImage(img.id)}
                                        onMouseEnter={() => changeImage(img.id)}
                                    >
                                        <img
                                            src={img.img}
                                            alt={img.alt}
                                            id="img1"
                                            className={
                                                imgIndex === img.id
                                                    ? "selected-img"
                                                    : ""
                                            }
                                        />
                                        {/* {console.log(
                                            (imgIndex === img.id) +
                                                imgIndex +
                                                "," +
                                                img.id
                                        )} */}
                                    </div>
                                ))}
                                <div
                                    className="head-img-slider-box"
                                    onClick={() => moveDown()}
                                >
                                    {downArrow}
                                </div>
                            </div>
                            <div className="head-img-buttons">
                                <CtaBtn1
                                    btnClass="cta-btn1 cta-btn1-var1"
                                    href
                                    link={
                                        "mailto:offert@realestatesecurity.se?Subject=Offert - " +
                                        products[productIndex].title
                                    }
                                    title="Skicka offert"
                                />
                            </div>
                        </div>
                        <div className="tablet-buttons">
                            <CtaBtn1
                                btnClass="cta-btn1 cta-btn1-var1"
                                href
                                link={
                                    "mailto:offert@realestatesecurity.se?Subject=Offert - " +
                                    title
                                }
                                title="Skicka offert"
                            />
                        </div>
                        <div className="title-box">
                            <h1>{products[productIndex].title}</h1>
                            <p>{products[productIndex].desc}</p>
                        </div>
                        <div className="extra-info">
                            {products[productIndex].specs && (
                                <div className="extra-info-col">
                                    <h5>Specifikationer</h5>
                                    <ul>
                                        {products[productIndex].specs.map(
                                            (spec) => (
                                                <li key={spec.id}>
                                                    <small>{spec.title}</small>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className="extra-info-col">
                                <div className="extra-info-col-top">
                                    <h5>Produktblad</h5>
                                    <ul>
                                        {products[productIndex].pdf.map(
                                            (pdf) => (
                                                <a href={pdf.href} key={pdf.id}>
                                                    <small>{pdf.title}</small>
                                                </a>
                                            )
                                        )}
                                    </ul>
                                </div>
                                <div className="extra-info-col-bottom"></div>
                            </div>
                        </div>
                        <div className="description">
                            <h3>Beskrivning</h3>
                            {products[productIndex].longDesc.map(
                                (textBlock) => {
                                    if (
                                        textBlock.charAt(0) === "%" &&
                                        textBlock.charAt(3) === "%"
                                    ) {
                                        if (textBlock.charAt(1) === "S") {
                                            return (
                                                <p key={textBlock.valueOf()}>
                                                    <strong>
                                                        {textBlock.substring(4)}
                                                    </strong>
                                                </p>
                                            );
                                        }
                                        if (textBlock.charAt(1) === "B") {
                                            return (
                                                <p key={textBlock.valueOf()}>
                                                    <br />
                                                    {textBlock.substring(4)}
                                                </p>
                                            );
                                        }
                                        if (textBlock.charAt(1) === "U") {
                                            return <ul></ul>;
                                        }
                                    }

                                    return (
                                        <p key={textBlock.valueOf()}>
                                            {textBlock}
                                        </p>
                                    );
                                }
                            )}
                        </div>
                        <div className="product">
                            <div className="title">
                                <h5>Rekommenderat</h5>
                            </div>
                            <ProductCards products={products} random />
                        </div>
                    </div>
                </div>
            </section>

            <section className="any-questions">
                <BodyF title1="Kontakta oss" titleLink1="/kontakt" />
            </section>
        </>
    );
};

export default ProductPage;
