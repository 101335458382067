import React from 'react'
import Slide from './Slide/Slide';


const Slider = ({swap, data}) => {
    

    return (
        <>
            <Slide
                src={data[swap].img}
                alt={data[swap].alt}
                loading={data[swap].loading}
                
            />
            <div className="layer"></div>
        </>
    )
}

export default Slider
