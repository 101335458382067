import React from "react";

const TeamBox = ({img, background, desc, mail, title, wholeName}) => {
    return (
        <div class="team-box">
            <div class="team-box-top">
                <div class="team-box-top-main-img">
                    <img
                        src={img}
                        alt=""
                        style={{width: "134%"}}
                    />
                </div>
                <div class="team-box-top-background-img">
                    <img
                        src={background}
                        alt=""
                    />
                </div>
            </div>
            <div class="team-box-bottom">
                <div class="title-text">
                    <h4 class="text-align-left">{wholeName}</h4>
                    <h4 class="text-align-right">{title}</h4>
                </div>
                <div class="desc">
                    <p>
                        {desc}
                    </p>
                </div>
                <a
                    href={"mailto:" + mail}
                    class="mail p"
                >
                    <i class="far fa-envelope-open"></i>
                    <p>{mail}</p>
                </a>
            </div>
        </div>
    );
};

export default TeamBox;
