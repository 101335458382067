import React from "react";
import { Link } from "react-router-dom";

const AboutUsBox = ({page}) => {
    return (
        <div className="om-oss-page-box-top" id="subheadMenu">
            <ul onScroll={""}>
                <div className="mobile-swipe-arrow" id="mobile-swipe-arrow">
                    <i className="fas fa-chevron-right"> </i>
                </div>
                <Link to="/om-oss">
                    <li className={page === "1" ? ("current-page") : ("")}>
                        <p>Om oss</p>
                    </li>
                </Link>
                <Link to="/om-foretaget">
                    <li className={page === "2" ? ("current-page") : ("")}>
                        <p>Om företaget</p>
                    </li>
                </Link>
                <Link to="/vart-team">
                    <li className={page === "3" ? ("current-page") : ("")}>
                        <p>Vårt team</p>
                    </li>
                </Link>
                <Link to="/vart-erbjudande">
                    <li className={page === "4" ? ("current-page") : ("")}>
                        <p>Vårt erbjudande</p>
                    </li>
                </Link>
            </ul>
        </div>
    );
};

export default AboutUsBox;
