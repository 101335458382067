import React from "react";
import BodyA from "../../body/BodyA";
import BodyB from "../../body/BodyB";
import BodyC from "../../body/BodyC";
import BodyD from "../../body/BodyD";
import BodyE from "../../body/BodyE";
import BodyF from "../../body/BodyF";
import Head from "./Head/Head";
import Helm from "../../Helm/Helm";

import img0 from "./stockholm_omslag.jpg";
import img1 from "./wise_house_tagglasare.jpg";

import img2 from "./overvakningskameror_fisheye-02.png";
import img3 from "./overvakningskameror_kvad.jpg";
import img4 from "./1-1_overvakningskameror_fisheye-02.png";

import imgCol1 from "./digitala_fastighetstjanster_kvad.jpg";
import imgCol2 from "./overvakningskameror_kvad.jpg";
import imgCol3 from "./speglad_naturek_TR342_sakerhetsdorr-1.jpg";
import imgCol4 from "./inbrottsskyddande_glas.jpg";
import imgCol5 from "./dimgenerator_icon.jpg";
import imgCol6 from "./trygghetsspray-front.jpg";

import img5 from "./samarbetspartners_mainimg.jpg";
import img6 from "./Om oss.jpg";


const IndexPage = () => {
    return (
        <>
            <Helm
                title={"Real Estate Security - Skapar trygghet och säkerhet i bostäder."}
                description={
                    "Vi har de modernaste och mest flexibla produkterna för din trygghet och säkerhet. Modern teknik på ett användarvänligt sätt för att skydda din egendom och personer du älskar."
                }
                link={"/"}
                ogImage
                ogTitle={"Real Estate Security - Skapar trygghet och säkerhet i bostäder."}
                ogDescription={
                    "Vi har de modernaste och mest flexibla produkterna för din trygghet och säkerhet. Modern teknik på ett användarvänligt sätt för att skydda din egendom och personer du älskar."
                }
                author
            />
            <section id="index-page">
                <Head />
                <section className="assortment">
                    <BodyC img={img2} icon1={img3} icon2={img4} />
                    <BodyD
                        imgCol1={imgCol1}
                        imgCol2={imgCol2}
                        imgCol3={imgCol3}
                        imgCol4={imgCol4}
                        imgCol5={imgCol5}
                        imgCol6={imgCol6}
                    />
                    
                </section>
                <section className="about-us" id="about-us">
                    <BodyA
                        img={img0}
                        title="Om företaget"
                        desc="Vi är ett ungt och entreprenaddrivet företag i fastighets- och säkerhetsbranschen som arbetar för att öka din trygghet genom att förstärka säkerheten i ditt boende eller på din arbetsplats."
                        link="/om-foretaget"
                        btnTitle="Läs mer"
                        
                        
                    />
                    <BodyB
                        img={img1}
                        title="Vårt erbjudande"
                        desc="Vi erbjuder tjänster som skall förhindra inbrott, vandalisering, rån, kriminell verksamhet och skadegörelse, genom att erbjuda marknadens vassaste produkter från utvalda leverantörer."
                        link="/vart-erbjudande"
                        btnTitle="Läs mer"
                        
                    />
                </section>
                
                <section className="learn-about-us">
                    <BodyE img1={img5} img2={img6} />
                </section>
                <section className="any-questions">
                    <BodyF title1="Kontakta oss" titleLink1="/kontakt" />
                </section>
            </section>
        </>
    );
};

export default IndexPage;
