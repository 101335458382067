import React, { useEffect, useState } from "react";
import DesktopMenuBar from "./DesktopMenuBar/DesktopMenuBar";
import MobileMenuBar from "./MobileMenuBar/MobileMenuBar";
import { CSSTransition } from "react-transition-group";
import { useWindowScroll } from "react-use";

import "./navbar.scss";

const NavBar = () => {
    const [open, setOpen] = useState(false);

    function toggleMenu() {
        setOpen(!open);

        console.log(open);
    }

    const { y } = useWindowScroll();
    const [scrolled, setScrolled] = useState(0);

    useEffect(() => {
        const height = 100;
        if(y / height <= 1) {
            setScrolled(y / height);
        } else {
            setScrolled(1);
        }
        
    }, [y]);

    

    return (
        <header className="header" id="header">
            <DesktopMenuBar toggleMenu={toggleMenu} open={open} scroll={scrolled} style={{backgroundColor: `rgba(255,255,255,${scrolled}`}}/>
            <CSSTransition
                in={open === true}
                unmountOnExit
                timeout={1000}
                classNames="menu-open"
            >
                <div className="menu-open">
                    <MobileMenuBar toggleMenu={toggleMenu} open={open} />
                </div>
            </CSSTransition>
        </header>
    );
};

export default NavBar;
