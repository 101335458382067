import React from 'react'
import { Link } from 'react-router-dom'

const MenuItem = ({className, link, title}) => {
    return (
        <>
            <li className={className}><Link to={link}>{title}</Link></li>
        </>
    )
}

export default MenuItem
